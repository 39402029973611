.feedbackMenu a {
  padding: 8px;
}

.feedbackDialog {
  display: grid !important;
  grid-template-rows: 50px auto !important;
}
.feedbackContainer {
  height: 100%;
  width: 100%;
  justify-content: flex-start !important;
}

@media only screen and (max-device-width: 600px) {
  .feedbackDialog {
    height: 100%;
  }
}

.feedbackForm {
  margin-top: 16px;
}

.feedbackModalElement {
  width: 100%;
}

.feedbackSlider {
  margin-top: 24px;
}
