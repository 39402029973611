.flex {
  display: flex;
}
.column {
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}
.grid {
  display: grid;
}
.grid-columns {
  grid-template-columns: repeat(var(--columns), 1fr);
}
.grid-rows {
  grid-template-rows: var(--rowHeight) repeat(var(--rows), 1fr);
}

.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

/* JUSTIFY CONTENT FOR FLEX */
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-evenly {
  justify-content: space-evenly;
}

/* ALIGN ITEMS FOR FLEX */
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-strech {
  align-items: stretch;
}
/* GAP SPACING */
.gap-smallest {
  gap: 4px;
}
.gap-smaller {
  gap: 8px;
}
.gap-small {
  gap: 12px;
}
.gap-medium {
  gap: 16px;
}
.gap-large {
  gap: 24px;
}
.gap-larger {
  gap: 32px;
}
.gap-largest {
  gap: 40px;
}
