
  /*Hack for making selectable items use cursor pointer*/
  .ac-selectable {
    cursor: pointer;
    border-radius: 24px;
    background-color: #6633ff !important;
  }
  
  .ac-selectable:disabled{
    cursor: not-allowed;
  }

  .ac-selectable p {
    color: white;
    padding-left: 2rem;
  }
  
  .ac-selectable:hover {
    background-color: #ccff33 !important;
    color: black;
  }
  
  .ac-selectable:hover p {
    color: black;
  }
  
  .ac-actionSet button {
    border-style: none !important;
  }

  
  .ac-pushButton:not(.clickedButton) div {
    color: white;
  }
  
  .ac-pushButton:hover div {
    color: black;
  }
  
  .ac-image {
    border-radius: 4px;
  }

  .webchat__send-box__main{
    border-radius:10px;
    border-top: none !important;
    width: 95%;
    margin-right: 1rem;
    margin-left: auto;
  }

  /* hides the weird borders when clicking on the chat, they are though I guess meant for accessibility so perhaps should reconsider*/
  [class*="webchat__basic-transcript"] {
    border: none !important;
    outline: none !important;
    }

  /* size of the chat div*/
.webchat {
    max-height: 95% !important;
    max-width: 92%;
  }


  @media only screen and (min-width: 600px) {
    .chatWrapper {
      width: 90%;
    }
  }
  
  @media only screen and (min-width: 800px) {
    .chatWrapper {
      width: 80%;
    }
    .webchat {
      max-height: 90% !important;
    }
  }
  @media only screen and (min-width: 1000px) {
    .chatWrapper {
      width: 60%;
    }
  }
  @media only screen and (min-width: 1200px) {
    .chatWrapper {
      width: 50%;
    }
  }
  .clickedButton {
    background-color: #ccff33 !important;
    color: black !important;
  }
