.trainingCatalogueContainer {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.trainingRow {
  cursor: pointer;
  width: 100%;
  padding: 4px;
  border-radius: 10px;
  border-width: thin;
}

.trainingCompleted {
  background-color: #33ff66;
}

.trainingExpiring {
  background-color: #ffcc33;
}

.expiringLabel {
  background-color: transparent !important;
  border: 1px solid;
  font-size: 12px;
}

.trainingStatsContainer {
  width: 30%;
  height: 100%;
  padding-bottom: 24px;
}
@media only screen and (max-width: 768px) {
  .trainingStatsContainer {
    width: 100%;
    height: 20%;
  }
}

.trainingStatsElement {
  width: 100%;
}

.trainingStatsElementContainer {
  width: 35%;
}

.trainingStatsElementDivider {
  width: 50%;
}
