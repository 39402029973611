.cardHeadline {
  margin: 0.2em 0;
}

.ui-table__row {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

#trainings ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

#trainingProgressBarContainer {
  background: gainsboro;
  height: 1.5em;
  padding: 0.1em;
  border-radius: 50px;
  box-shadow: 0 0 1px 0 rgba(#000, 0.75) inset, 0 0 4px 0 rgba(#000, 0.5);
  align-self: center;
}

#trainingProgressBar {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset, 0 0 1px 2px rgba(white, 0.75) inset;
  background: transparent;
}

#trainingProgressBarBackground {
  background-image: linear-gradient(to right, #ccff33, #6633ff, #ff33cc, #cc33ff, #33ff66);
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

#trainingProgressBarBackground:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50px;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
.profileCard {
  min-width: 100%;
  max-height: 50rem;
}

.profileView {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
#trainings {
  max-height: 50rem;
}

@media only screen and (min-width: 50rem) {
  .profileCard {
    min-height: 45vh;
    max-height: 25rem;
    overflow-y: scroll;
  }
  #trainings {
    min-height: 45vh;
    max-height: 25rem;
  }
  .profileView {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  #badges {
    min-height: 91vh;
    max-height: 50.5rem;
    grid-row: 1/2 span;
    grid-column: 2;
  }
  #trainings {
    grid-row: 2;
  }
  #customContent {
    max-height: 25rem;
    max-height: 45vh;
  }
}

@media only screen and (min-width: 80rem) {
  .profileView {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 100rem) {
  .profileView {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
